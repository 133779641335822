// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import {
  ReportingObserver as ReportingObserverIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
  Dedupe as DedupeIntegration,
  Offline as OfflineIntegration,
} from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://6935d9e0807445899ad5c8ea52041332@o574888.ingest.sentry.io/5726442",
  release: SENTRY_RELEASE,
  autoSessionTracking: true,
  normalizeDepth: 5,
  tracesSampleRate: 1,
  integrations: [
    new ReportingObserverIntegration(),
    new ExtraErrorDataIntegration({
      // limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 5,
    }),
    new CaptureConsoleIntegration(),
    // {
    // array of methods that should be captured
    // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
    // levels: string[];
    // }
    new DedupeIntegration(),
    new OfflineIntegration({
      // limit how many events will be localled saved. Defaults to 30.
      maxStoredEvents: 100,
    }),
  ],
});
